'use client'

import { memo, PropsWithChildren, useMemo, useState } from 'react'

import { DrawerContext } from '@/context/conversationDrawer/drawer.context'

export const DrawerProvider = memo(({ children }: PropsWithChildren) => {
  const [open, setOpenContext] = useState<boolean>(false)
  const [selectedConversation, setSelectedConversation] = useState<
    string | undefined
  >()

  const show = () => {
    setOpenContext(true)
  }

  const close = () => {
    setOpenContext(false)
  }

  const contextValue = useMemo(() => {
    return {
      open,
      show,
      close,
      selectedConversation,
      setSelectedConversation,
    }
  }, [open, selectedConversation])

  return (
    <DrawerContext.Provider value={contextValue}>
      {children}
    </DrawerContext.Provider>
  )
})
