'use client'

import { DatePicker, Select } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import useIps from '@/hooks/useIps'

import { ACCESS_LOG_TYPE } from '@/constants/admin'

import { ILogsFilters } from '@/types/admin'

type RangeValue = Parameters<
  NonNullable<React.ComponentProps<typeof DatePicker.RangePicker>['onChange']>
>[0]

interface FiltersProps {
  handleFiltersChange: (setFilters: ILogsFilters) => void
  filters: ILogsFilters
  showTypeFilter?: boolean
}

const Filters: React.FC<FiltersProps> = ({
  filters,
  handleFiltersChange,
  showTypeFilter,
}) => {
  const { t } = useTranslation()
  const [dateTime, setDateTime] = useState<RangeValue>()
  const { ips } = useIps()

  const { RangePicker } = DatePicker

  const handleDateTimeChange = (newDateTime: RangeValue) => {
    setDateTime(newDateTime)
    if (newDateTime) {
      handleFiltersChange({
        ...filters,
        fromTimestamp: newDateTime[0]?.valueOf() ?? null,
        toTimestamp: newDateTime[1]?.valueOf() ?? null,
      })
    } else {
      handleFiltersChange({
        ...filters,
        fromTimestamp: null,
        toTimestamp: null,
      })
    }
  }

  return (
    <div className='flex w-full flex-col items-center gap-2.5 lg:max-w-[45rem] lg:flex-row'>
      <RangePicker
        className='w-full'
        placeholder={[t('start-date'), t('end-date')]}
        showTime={{ format: 'HH:mm' }}
        value={dateTime}
        onChange={handleDateTimeChange}
        format='DD-MM-YYYY HH:mm'
        changeOnBlur
      />
      {showTypeFilter && (
        <Select
          className='w-full text-left'
          mode='multiple'
          allowClear
          maxTagCount='responsive'
          placeholder={t('Type')}
          value={filters.types}
          onChange={(value) =>
            handleFiltersChange({ ...filters, types: value })
          }
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          options={Object.values(ACCESS_LOG_TYPE).map((type: string) => ({
            label: type,
            value: type,
          }))}
        />
      )}
      <Select
        className='w-full text-left'
        mode='multiple'
        allowClear
        maxTagCount='responsive'
        placeholder={t('ips')}
        value={filters.ips}
        onChange={(value) => handleFiltersChange({ ...filters, ips: value })}
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        options={ips.map((type: string) => ({
          label: type,
          value: type,
        }))}
      />
    </div>
  )
}

export default Filters
