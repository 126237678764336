import(/* webpackMode: "eager" */ "/app/node_modules/antd/dist/reset.css");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/app/node_modules/next-themes/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/app/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Admin/Modals/ModalsContainer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/ErrorBoundary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/WebsocketProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DrawerProvider"] */ "/app/src/context/conversationDrawer/drawer.provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LoadingProvider"] */ "/app/src/context/loading/loading.provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/context/modals/modals.provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TourProvider"] */ "/app/src/context/tour/tour.provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/providers/antd-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/providers/i18n-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/providers/mui-provider.tsx");
